* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

h1 {
  margin: 0;
}

.funky {
  transition: all 0.5s ease;
  cursor: pointer;
  font-family: cursive;
  color: white;
}
.funky:hover {
  font-size: 15vw;
  color: var(--random-color);
  transform: rotate(360deg);
}

hr {
  background-color: var(--random-color);
  width: 50%;
  height: 5px;
}
